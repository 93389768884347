<template>
  <div>
    <v-text-field
      @click="onClick"
      :success-messages="showTinVerification && user.tinVerifiedDate ? returnMessage : null"
      v-if="mask"
      :value="displayValue"
      :label="labelValue"
      v-bind="$attrs"
      v-on="$listeners"
      readonly
    >
      <template v-slot:append v-if="showTooltip">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" color="primary" dark>
              mdi-information-outline
            </v-icon>
          </template>
          <span>{{ tooltipMessage }}</span>
        </v-tooltip>
      </template>
    </v-text-field>
    <v-dialog v-model="dialog" v-if="country" persistent max-width="500">
      <v-card :loading="loading">
        <v-form v-model="valid">
          <v-card-text>
            <v-text-field
              :label="country.participantTinName"
              :country="country"
              v-model="fullSsn"
              v-mask="{ mask: [...mask], tokens: tokens }"
              :rules="[validate]"
            />
            <AlertDisplay ref="alertRef" durationOption="never" />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="onCancel" :loading="loading">
              Cancel
            </v-btn>
            <v-btn color="primary" @click="onSave" :disabled="!valid" :loading="loading">
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UtilService from "../../services/util.service";
import AlertDisplay from "@/gapp-components/components/display/AlertDisplay.vue";

export default {
  name: "TinField",
  components: { AlertDisplay },
  data: () => ({
    loading: false,
    dialog: false,
    valid: true,
    fullSsn: "",
    displayValue: "",
    labelValue: null,
    mask: ["###-##-####"],
    tokens: {
      "#": { pattern: /\d/ },
      X: { pattern: /[0-9a-zA-Z]/, transform: v => v.toLocaleUpperCase() },
      S: { pattern: /[a-zA-Z]/ },
      A: { pattern: /[a-zA-Z]/, transform: v => v.toLocaleUpperCase() },
      a: { pattern: /[a-zA-Z]/, transform: v => v.toLocaleLowerCase() },
      "!": { escape: true }
    }
  }),
  props: {
    value: String,
    country: { type: Object, required: true },
    user: Object,
    showTinVerification: { type: Boolean, required: false, default: false },
    showTooltip: {
      type: Boolean,
      default: false
    },
    tooltipMessage: {
      type: String,
      default: ""
    }
  },
  methods: {
    onClick() {
      this.fullSsn = "";
      this.dialog = true;
    },
    onCancel() {
      this.dialog = false;
      this.resetAlertDisplay();
    },
    onSave() {
      if (this.user) {
        this.loading = true;
        let service;
        if (this.user.id != this.currentUser.user.id && this.$privilege.hasPrivilege("USER_UPDATE")) {
          service = this.$api.post("/api/users/" + +this.user.id + "/setTin", { tin: this.fullSsn });
        } else if (this.user.id == this.selectedParticipant.user.id) {
          service = this.$api.post("/api/users/setTin", { tin: this.fullSsn });
        } else {
          // no permission to update TIN
          this.loading = false;
          return;
        }

        service
          .then(() => {
            this.dialog = false;
            let ssnWithoutSpecialChars = this.fullSsn.replace(/[\W_]+/g, "");
            this.displayValue =
              ssnWithoutSpecialChars.length > 4
                ? ssnWithoutSpecialChars.substring(ssnWithoutSpecialChars.length - 4)
                : ssnWithoutSpecialChars;
            this.$emit("input", this.displayValue);
          })
          .catch(error => {
            if (error.response && error.response.data && error.response.data.errors) {
              let errors = error.response.data.errors;
              let tinError = errors.find(err => err.message === "TIN already exists");
              if (tinError) {
                this.$refs.alertRef.error(
                  `SSN already exists. Please contact Program Headquarters at ${this.selectedProgram.phoneType1Description} for assistance.`
                );
              } else {
                console.error(
                  "An error occurred while updating SSN: " + (error.response.data.errorMessage || "Unknown error")
                );
              }
            } else {
              console.error("An unexpected error occurred.");
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        let ssnWithoutSpecialChars = this.fullSsn.replace(/[\W_]+/g, "");
        this.displayValue =
          ssnWithoutSpecialChars.length > 4
            ? ssnWithoutSpecialChars.substring(ssnWithoutSpecialChars.length - 4)
            : ssnWithoutSpecialChars;
        this.$emit("input", this.displayValue);
      }
    },
    resetAlertDisplay() {
      if (this.$refs.alertRef) {
        this.$refs.alertRef.resetAlert();
      }
    },
    validate(ssn) {
      if (this.country && this.country.participantTinRegex && !!ssn) {
        let regex = RegExp(this.country.participantTinRegex);
        if (!regex.test(ssn)) {
          return "Invalid " + this.labelValue;
        }
      }
      if (this.user) {
        if (this.user.id != this.currentUser.user.id && this.$privilege.hasPrivilege("USER_UPDATE")) {
          // user has permission
        } else if (this.user.id == this.selectedParticipant.user.id) {
          // user has permission (self)
        } else {
          // no permission to update TIN
          return "Access denied to change value";
        }
      }
      return true;
    },
    userChange() {
      if (this.user) {
        this.displayValue = this.user ? this.user.tin : "";
      } else if (this.value) {
        this.displayValue = this.value;
      }
      this.dialog = false;
    }
  },
  watch: {
    country: function() {
      if (this.label) {
        this.labelValue = this.label;
      } else {
        if (this.country) {
          this.labelValue = "Last 4 of " + this.country.participantTinName;
        } else {
          this.labelValue = "Last 4 of SSN (Select Country First)";
        }
      }
      if (this.country && this.country.participantTinMask) {
        this.mask = this.country.participantTinMask;
      } else {
        this.mask = ["###-##-####"];
      }
    },
    user: function() {
      this.userChange();
    }
  },
  mounted() {
    // label
    if (this.label) {
      this.labelValue = this.label;
    } else {
      if (this.country) {
        this.labelValue = "Last 4 of " + this.country.participantTinName;
      } else {
        this.labelValue = "Last 4 of SSN (Select Country First)";
      }
    }

    // mask
    if (this.country && this.country.participantTinMask) {
      this.mask = this.country.participantTinMask;
    }

    this.userChange();
  },
  computed: {
    ...mapGetters(["currentUser", "selectedParticipant", "selectedClient", "selectedProgram"]),
    returnMessage() {
      return (
        this.country.participantTinName +
        " verified on " +
        UtilService.formatDateClient(this.user.tinVerifiedDate, "MM/DD/YYYY", this.selectedClient)
      );
    }
  }
};
</script>
