<template>
  <v-card flat v-bind="$attrs" v-on="$listeners" :loading="loading">
    <div class="text-center" v-if="loading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <v-container v-else>
      <v-alert border="top" type="info" v-if="securityQuestions && securityQuestions.length == 0" class="mt-0">
        Security questions are not configured. There is no setup required on this page.
      </v-alert>
      <div v-else>
        <v-alert border="top" type="info">
          Security questions help keep your account secure. You may be prompted with one of these questions during
          login.
        </v-alert>
        <v-form
          ref="securityQuestionForm"
          @submit.prevent="onPasswordSubmit"
          v-if="!loading"
          v-model="validForm"
          @input="emit"
        >
          <v-alert dismissible type="success" v-if="profileUpdated">
            Security Questions Updated Successfully
          </v-alert>
          <v-container>
            <v-row v-for="(item, index) in amountOfSecurityQuestions" :key="item">
              <v-col cols="6">
                <v-select
                  v-if="securityQuestionForm && securityQuestionForm[index]"
                  :items="filteredSecurityQuestions[index]"
                  item-text="question"
                  item-value="id"
                  @change="
                    filterQuestions();
                    securityQuestionForm[index].answer = '';
                  "
                  v-model="securityQuestionForm[index].questionId"
                  :label="'Security Question ' + (index + 1)"
                >
                  <template v-slot:selection="{ item }">
                    <span class="text-wrap">{{ item.question }}</span>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-if="securityQuestionForm && securityQuestionForm[index]"
                  name="securityAnswer1"
                  :label="'Answer ' + (index + 1)"
                  :id="'securityAnswer' + index"
                  v-model="securityQuestionForm[index].answer"
                  :rules="rules.securityAnswer"
                  :error-messages="$error.getValidationError(errors, 'securityAnswer')"
                  @input="$error.clearValidationError(errors, 'securityAnswer')"
                  class="required"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn type="submit" color="primary" @click="onSubmit" :disabled="!value">Update</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import ValidationService from "@/gapp-components/services/validation.service.js";
import Vue from "vue";
import { mapGetters } from "vuex";
import ApiService from "../../../services/api.service";
import { SECURITY_ANSWER_COUNT, SELECT_PARTICIPANT } from "../../../store/participant.module";

export default {
  name: "ProfileSecurityQuestionsTab",
  props: {
    value: [Boolean, Event]
  },
  data: () => ({
    loading: false,
    validForm: true,
    securityQuestions: [],
    amountOfSecurityQuestions: 0,
    securityQuestionForm: [],
    filteredSecurityQuestions: [],
    rules: {
      securityAnswer: ValidationService.generateTextValidationRules("Answer")
    },
    errors: {},
    profileUpdated: false
  }),
  methods: {
    emit() {
      this.$emit("input", this.validForm);
    },
    fetchData() {
      this.loading = true;
      this.amountOfSecurityQuestions = this.selectedProgram.programGroup.amountOfSecurityQuestions;
      return ApiService.post("/api/securityQuestions/search?page=0&size=100", {})
        .then(({ data }) => {
          this.securityQuestions = data.content;

          for (let i = 0; i < this.amountOfSecurityQuestions; i++) {
            this.filteredSecurityQuestions[i] = [...this.securityQuestions];
          }
          return ApiService.get("/api/securityAnswers/getByUser")
            .then(({ data }) => {
              this.securityQuestionForm = data;
              this.$store.dispatch(SECURITY_ANSWER_COUNT, data.length);
              if (data.length == 0) {
                for (let i = 0; i < this.amountOfSecurityQuestions; i++) {
                  this.securityQuestionForm.push({
                    questionId: this.securityQuestions[i].id,
                    answer: ""
                  });
                }
              }
            })
            .catch(error => {
              this.errors = ApiService.getErrorsFromResponse(error);
            })
            .finally(() => {
              this.filterQuestions();
              this.loading = false;
              Vue.nextTick().then(() => {
                if (this.$refs && this.$refs.securityQuestionForm) {
                  this.$refs.securityQuestionForm.validate();
                }
              });
              this.$emit("onLoad");
            });
        })
        .catch(error => {
          this.errors = ApiService.getErrorsFromResponse(error);
        });
    },
    onSubmit() {
      this.loading = true;
      this.errors = {};
      let postForm = Object.assign([], this.securityQuestionForm);
      ApiService.post("/api/securityAnswers/saveAnswers", postForm)
        .then(() => {
          ApiService.get("/api/participants/bySelectedParticipant").then(({ data }) => {
            this.$store.dispatch(SELECT_PARTICIPANT, data).then(() => {
              ApiService.get("/api/securityAnswers/getByUser").then(({ data }) => {
                this.$store.dispatch(SECURITY_ANSWER_COUNT, data.length).then(() => {
                  window.scrollTo(0, 0);
                  this.profileUpdated = true;
                  setTimeout(() => {
                    this.profileUpdated = false;
                  }, 4000);
                });
              });
            });
          });
        })
        .catch(error => {
          this.errors = this.errors.concat(ApiService.getErrorsFromResponse(error));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    filterQuestions() {
      for (let i = 0; i < this.amountOfSecurityQuestions; i++) {
        this.filteredSecurityQuestions[i] = [...this.securityQuestions];
        for (let j = 0; j < this.amountOfSecurityQuestions; j++) {
          if (i != j) {
            this.filteredSecurityQuestions[i].splice(
              this.filteredSecurityQuestions[i]
                .map(x => x.id)
                .indexOf(
                  this.securityQuestionForm && this.securityQuestionForm[j]
                    ? this.securityQuestionForm[j].questionId
                    : 0
                ),
              1
            );
          }
        }
      }
    },
    onEdit() {
      this.passwordForm = {};
    },
    onClose() {
      this.fetchData();
    }
  },
  mounted() {
    this.fetchData();
    this.validForm = this.value;
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedProgram"])
  }
};
</script>
