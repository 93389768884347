<template>
  <v-card flat v-bind="$attrs" v-on="$listeners" :loading="loading">
    <div class="text-center" v-if="loading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <v-form ref="participantForm" @submit.prevent="onSubmit" v-if="!loading" v-model="validForm" @input="input">
      <v-container>
        <v-row>
          <v-col class="pb-0">
            <v-alert border="top" type="info">
              Welcome {{ userForm.fullName }}. Please use the form below to update your information.
            </v-alert>
            <v-alert dismissible border="top" type="success" v-if="profileUpdated">
              Contact Updated Successfully
            </v-alert>
            <slot v-if="!isModifiable" name="messageWhenParticipantTabIsNotEditable"
              >Below is your information on file</slot
            >
          </v-col>
        </v-row>

        <v-row v-if="showName && userForm">
          <v-col cols="6">
            <v-text-field
              dense
              name="firstName"
              :label="$i18n.translate('First Name')"
              v-model="userForm.firstName"
              :class="requireName ? 'required' : ''"
              :rules="rules.firstName"
              :error-messages="$error.getValidationError(errors, 'firstName')"
              @input="$error.clearValidationError(errors, 'firstName')"
              :disabled="!isModifiable"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              dense
              name="lastName"
              :label="$i18n.translate('Last Name')"
              v-model="userForm.lastName"
              :class="requireName ? 'required' : ''"
              :rules="rules.lastName"
              :error-messages="$error.getValidationError(errors, 'lastName')"
              @input="$error.clearValidationError(errors, 'lastName')"
              :disabled="!isModifiable"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="showEmail">
          <v-col cols="12">
            <EmailField
              dense
              name="email1"
              :label="$i18n.translate('Email')"
              v-model="participantForm.email1"
              :class="requireEmail ? 'required' : ''"
              :rules="rules.email1"
              :error-messages="$error.getValidationError(errors, 'email1')"
              @input="$error.clearValidationError(errors, 'email1')"
              :disabled="!isModifiable"
            ></EmailField>
          </v-col>
        </v-row>
        <v-row v-if="showAddress">
          <v-col cols="6">
            <v-text-field
              dense
              name="address1"
              :label="$i18n.translate('Address') + ' 1'"
              v-model="participantForm.address.address1"
              :class="requireAddress ? 'required' : ''"
              :rules="rules.address1"
              :error-messages="$error.getValidationError(errors, 'address1')"
              @input="$error.clearValidationError(errors, 'address1')"
              :disabled="!isModifiable"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              dense
              name="address2"
              :label="$i18n.translate('Address') + ' 2'"
              v-model="participantForm.address.address2"
              :rules="rules.address2"
              :error-messages="$error.getValidationError(errors, 'address2')"
              @input="$error.clearValidationError(errors, 'address2')"
              :disabled="!isModifiable"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="showAddress">
          <v-col cols="6">
            <v-text-field
              dense
              name="city"
              :label="$i18n.translate('City')"
              v-model="participantForm.address.city"
              :class="requireAddress ? 'required' : ''"
              :rules="rules.city"
              :error-messages="$error.getValidationError(errors, 'city')"
              @input="$error.clearValidationError(errors, 'city')"
              :disabled="!isModifiable"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <CountryField
              dense
              name="country"
              :label="$i18n.translate('Country')"
              v-model="participantForm.address.country"
              :program="selectedProgram"
              :class="requireAddress ? 'required' : ''"
              :rules="rules.country"
              :error-messages="$error.getValidationError(errors, 'country')"
              @input="$error.clearValidationError(errors, 'country')"
              :disabled="!isModifiable || disableCountryField"
            ></CountryField>
          </v-col>
        </v-row>
        <v-row v-if="showAddress">
          <v-col cols="6">
            <RegionField
              dense
              name="region"
              v-model="participantForm.address.region"
              :country="participantForm.address.country"
              :class="requireAddress ? 'required' : ''"
              :rules="rules.region"
              :error-messages="$error.getValidationError(errors, 'region')"
              @input="$error.clearValidationError(errors, 'region')"
              :disabled="!isModifiable"
            ></RegionField>
          </v-col>
          <v-col cols="6">
            <PostalCodeField
              dense
              name="postalCode"
              v-model="participantForm.address.postalCode"
              :country="participantForm.address.country"
              :class="requireAddress ? 'required' : ''"
              :rules="rules.postalCode"
              :error-messages="$error.getValidationError(errors, 'postalCode')"
              @input="$error.clearValidationError(errors, 'postalCode')"
              :disabled="!isModifiable"
            ></PostalCodeField>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" v-if="showPhoneNumber1">
            <PhoneNumberField
              dense
              name="phoneNumber1"
              :label="this.selectedProgram.phoneType1Description"
              v-model="participantForm.phoneNumber1"
              :country="participantForm.address.country"
              :class="requirePhoneNumber1 ? 'required' : ''"
              :rules="rules.phoneNumber"
              :error-messages="$error.getValidationError(errors, 'phoneNumber1')"
              @input="$error.clearValidationError(errors, 'phoneNumber1')"
              :disabled="!isModifiable"
            ></PhoneNumberField>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" v-if="selectedParticipant.taxable">
            <TinField
              dense
              :country="participantForm.address.country"
              :user="userForm"
              :class="selectedParticipant.taxable ? 'required' : ''"
              :rules="rules.tin"
              :disabled="!isModifiable || !participantForm.address.country"
              :showTooltip="true"
              :tooltipMessage="tooltipMessage"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" v-if="showGender">
            <GenderField
              dense
              name="gender"
              id="gender"
              v-model="userForm.gender"
              :label="$i18n.translate('Gender')"
              :class="requireGender ? 'required' : ''"
              :rules="requireGender ? rules.gender : undefined"
              :error-messages="$error.getValidationError(errors, 'gender')"
              @input="$error.clearValidationError(errors, 'gender')"
              autocomplete="off"
              :disabled="!isModifiable"
            ></GenderField>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" v-if="showBirthDate">
            <DateTimePickerField
              dense
              v-model="userForm.birthDate"
              :disabled="!isModifiable"
              :label="$i18n.translate('Birth Date')"
              :class="requireBirthDate ? 'required' : ''"
              :rules="requireBirthDate ? rules.birthDate : undefined"
              :error-messages="$error.getValidationError(errors, 'birthDate')"
              @input="$error.clearValidationError(errors, 'birthDate')"
              onlyDate
              format="MM/DD/YYYY"
            ></DateTimePickerField>
          </v-col>
        </v-row>

        <v-row v-if="showTermsAndConditions">
          <v-col cols="12">
            <TermsAndConditionsView
              :value="termsAndConditions"
              @update:valid="validTermsAndConditions = $event"
              :required="selectedParticipant.participantType.requireTermsAndConditions === 'REQUIRED'"
          /></v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn v-if="isModifiable" type="submit" color="primary" @click="onSubmit" :disabled="!isValidForm">
              Update
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "../../../services/api.service";
import TermsAndConditionsView from "../../display/TermsAndConditionsView.vue";
import ValidationService from "@/gapp-components/services/validation.service.js";
import CountryField from "../../fields/CountryField";
import DateTimePickerField from "../../fields/DateTimePickerField.vue";
import EmailField from "../../fields/EmailField";
import GenderField from "../../fields/GenderField.vue";
import PhoneNumberField from "../../fields/PhoneNumberField";
import PostalCodeField from "../../fields/PostalCodeField";
import RegionField from "../../fields/RegionField";
import TinField from "../../fields/TinField";

export default {
  name: "ProfileParticipantTab",
  components: {
    CountryField,
    RegionField,
    PostalCodeField,
    PhoneNumberField,
    TinField,
    EmailField,
    TermsAndConditionsView,
    GenderField,
    DateTimePickerField
  },
  props: {
    value: [Boolean, Event]
  },
  data: () => ({
    validForm: false,
    loading: true,
    isModifiable: false,
    disableCountryField: true,
    userForm: {},
    participantForm: {
      address: {
        address1: "",
        country: {},
        region: {}
      }
    },
    participantType: null,
    errors: {},
    profileUpdated: false,
    validTermsAndConditions: false,
    termsAndConditions: {},
    tooltipMessage:
      "Some activities and earnings within this program are taxable and require us to file a 1099 or T4A. Please provide your Tax Identification number in order to earn in this program."
  }),
  methods: {
    input() {
      this.$emit("input", this.isValidForm);
    },

    fetchData() {
      this.loading = true;
      this.errors = {};
      ApiService.get("/api/participants/" + this.selectedParticipant.id)
        .then(({ data }) => {
          this.participantForm = data;
          this.isModifiable = !(this.participantForm.source && this.participantForm.source.length > 0);
          this.participantForm.address = this.participantForm.address ? this.participantForm.address : {};
          this.disableCountryField = this.participantForm.address.country == undefined ? false : true;

          return this.$api.getRelatedObject("user", data).then(({ data }) => {
            this.userForm = data;
            this.updateLastFourDigits(data.tin);
          });
        })
        .catch(error => {
          this.errors = ApiService.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
          this.$nextTick(() => {
            if (this.$refs && this.$refs.participantForm) {
              this.$refs.participantForm.validate();
            }
          });
          this.$emit("onLoad");
        });
    },
    onSubmit() {
      if (!this.isModifiable) {
        return;
      }

      this.loading = true;
      this.errors = {};

      let postParticipantForm = Object.assign({}, this.participantForm);
      let postUserForm = {
        firstName: this.userForm.firstName,
        lastName: this.userForm.lastName,
        gender: this.userForm.gender,
        birthDate: this.userForm.birthDate
      };

      if (this.validTermsAndConditions) {
        postParticipantForm.termsAndConditionsAccepted = true;
      }
      if (this.participantForm.address && this.participantForm.address.city) {
        this.participantForm.address.city = this.participantForm.address.city.trim();
      }

      Promise.all([
        this.$api.patch("/api/participants/" + this.selectedParticipant.id, postParticipantForm).then(({ data }) => {
          this.participantForm = data;
          if (!this.participantForm.address) {
            this.participantForm.address = {};
          }
        }),
        this.$api.patch("/api/users/" + this.userForm.id, postUserForm).then(({ data }) => {
          this.userForm = data;
        })
      ])
        .then(() => {
          this.$api.get("/api/participants/bySelectedParticipant").then(({ data }) => {
            this.userForm = data.user;
            this.$auth.storeSelectParticipant(data).then(() => {
              window.scrollTo(0, 0);
              this.profileUpdated = true;
              setTimeout(() => {
                this.profileUpdated = false;
              }, 4000);
              return data;
            });
          });
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
          this.fetchData();
        });
    },
    updateLastFourDigits(ssn) {
      if (this.participantForm.address.country) {
        let aux = this.participantForm.address.country.participantTinMask;
        let counter2 = 3;
        for (let i = aux.length - 1; i >= 0; i--) {
          if (aux.substr(i, 1) == "#") {
            if (counter2 >= 0) {
              aux = aux.substr(0, i) + ssn.substr(counter2, 1) + aux.substr(i + 1);
              counter2--;
            }
          }
        }
        this.lastFourDigits = aux.replaceAll("#", "*");
      }
    },
    getTermsAndConditions() {
      if (this.selectedLocale) {
        let selectedLocaleName = this.selectedLocale.languageType.name;
        if (this.selectedProgram && this.selectedProgram.defaultTermsAndConditions) {
          let termsAndConditions = this.selectedProgram.defaultTermsAndConditions.filter(
            tac => tac.language.name == selectedLocaleName
          );
          if (termsAndConditions.length > 0) {
            this.termsAndConditions = termsAndConditions[0];
          }
        }
      }
    }
  },
  mounted() {
    this.getTermsAndConditions();
    this.fetchData();
    this.validForm = this.value;
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedProgram", "selectedLocale"]),

    isValidForm() {
      if (!this.isModifiable) {
        return true;
      }
      return this.validForm && this.isValidTermsAndConditions;
    },

    showName() {
      return this.selectedParticipant.participantType.requireName != "INVISIBLE";
    },
    requireName() {
      return this.selectedParticipant.participantType.requireName === "REQUIRED";
    },

    showAddress() {
      return this.selectedParticipant.participantType.requireAddress != "INVISIBLE";
    },
    requireAddress() {
      return this.selectedParticipant.participantType.requireAddress === "REQUIRED";
    },

    showPhoneNumber1() {
      return this.selectedParticipant.participantType.requirePhone != "INVISIBLE";
    },
    requirePhoneNumber1() {
      return this.selectedParticipant.participantType.requirePhone === "REQUIRED";
    },

    showEmail() {
      return this.selectedParticipant.participantType.requireEmail != "INVISIBLE";
    },
    requireEmail() {
      return this.selectedParticipant.participantType.requireEmail === "REQUIRED";
    },

    showTermsAndConditions() {
      return (
        !this.participantForm.termsAndConditionsAccepted &&
        this.selectedParticipant.participantType.requireTermsAndConditions != "INVISIBLE"
      );
    },
    requireTermsAndConditions() {
      return (
        !this.participantForm.termsAndConditionsAccepted &&
        this.selectedParticipant.participantType.requireTermsAndConditions === "REQUIRED"
      );
    },

    showBirthDate() {
      return this.selectedParticipant.participantType.requireBirthDate != "INVISIBLE";
    },
    requireBirthDate() {
      return this.selectedParticipant.participantType.requireBirthDate === "REQUIRED";
    },

    showGender() {
      return this.selectedParticipant.participantType.requireGender != "INVISIBLE";
    },
    requireGender() {
      return this.selectedParticipant.participantType.requireGender === "REQUIRED";
    },

    isValidTermsAndConditions() {
      if (this.requireTermsAndConditions) {
        return this.validTermsAndConditions;
      }

      return true;
    },
    rules() {
      return {
        firstName: [
          ValidationService.generateNotWhitespacesRule("First Name"),
          ...(this.requireName ? ValidationService.generateTextValidationRules("First Name") : [])
        ],
        lastName: [
          ValidationService.generateNotWhitespacesRule("Last Name"),
          ...(this.requireName ? ValidationService.generateTextValidationRules("Last Name") : [])
        ],
        tin: this.selectedParticipant.taxable ? ValidationService.generateTextValidationRules("TIN") : [],
        address1: [
          ValidationService.generateNotWhitespacesRule("Address 1"),
          ...(this.requireAddress ? ValidationService.generateAddressValidationRules("Address 1") : [])
        ],
        address2: [ValidationService.generateNotWhitespacesRule("Address 2")],
        city: [
          ValidationService.generateNotWhitespacesRule("City"),
          ...ValidationService.generateTextValidationRules("City", 100, this.requireAddress)
        ],
        country: this.requireAddress ? ValidationService.generateDropdownValidationRules("Country") : [],
        region: this.requireAddress ? ValidationService.generateDropdownValidationRules("Region") : [],
        postalCode: this.requireAddress ? ValidationService.generateTextValidationRules("Postal Code") : [],
        phoneNumber: this.requirePhoneNumber1 ? ValidationService.generateTextValidationRules("Phone Number") : [],
        email1: this.requireEmail ? ValidationService.generateEmailValidationRules("Email") : []
      };
    }
  }
};
</script>
