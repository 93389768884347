<template>
  <v-progress-circular :size="20" indeterminate color="primary" v-if="loading"></v-progress-circular>
  <v-card v-else>
    <v-card-title class="elevation-5">
      <slot name="title">
        <span>{{ $i18n.translate("Profile") }}</span>
      </slot>
    </v-card-title>
    <v-card-text class="ma-0 pa-0">
      <v-container>
        <v-row>
          <v-col>
            <v-tabs vertical>
              <slot name="preTabs"></slot>
              <v-tab v-if="!hideContactTab" style="justify-content: left">
                <v-icon left>
                  mdi-account
                </v-icon>
                <v-badge icon="mdi-exclamation-thick" color="error" v-if="validParticipantForm == false">
                  <span class="d-none d-sm-flex"
                    ><slot name="contactInformationTabName"
                      >{{ $i18n.translate("Contact") }} {{ $i18n.translate("Information") }}</slot
                    ></span
                  >
                </v-badge>
                <span v-else>
                  <span class="d-none d-sm-flex"
                    ><slot name="contactInformationTabName"
                      >{{ $i18n.translate("Contact") }} {{ $i18n.translate("Information") }}</slot
                    ></span
                  >
                </span>
              </v-tab>
              <v-tab v-if="!hideOrganizationTab" style="justify-content: left">
                <v-icon left>
                  mdi-domain
                </v-icon>
                <v-badge icon="mdi-exclamation-thick" color="error" v-if="validOrganizationForm == false">
                  <span class="d-none d-sm-flex"
                    ><slot name="organizationTabName">
                      {{ $i18n.translate("Organization") }} {{ $i18n.translate("Information") }}</slot
                    ></span
                  >
                </v-badge>
                <span v-else>
                  <span class="d-none d-sm-flex"
                    ><slot name="organizationTabName"
                      >{{ $i18n.translate("Organization") }} {{ $i18n.translate("Information") }}</slot
                    ></span
                  >
                </span>
              </v-tab>
              <v-tab style="justify-content: left">
                <v-icon left>
                  mdi-lock-question
                </v-icon>
                <v-badge icon="mdi-exclamation-thick" color="error" v-if="validSecurityForm == false">
                  <span class="d-none d-sm-flex"
                    ><slot name="securityQuestionTabName">{{ $i18n.translate("Security Questions") }}</slot></span
                  >
                </v-badge>
                <span v-else>
                  <span class="d-none d-sm-flex"
                    ><slot name="securityQuestionTabName">{{ $i18n.translate("Security Questions") }}</slot></span
                  >
                </span>
              </v-tab>
              <v-tab style="justify-content: left">
                <v-icon left>
                  mdi-lock
                </v-icon>
                <span class="d-none d-sm-flex"
                  ><slot name="passwordTabName">{{ $i18n.translate("Change Password") }}</slot></span
                >
              </v-tab>
              <v-tab style="justify-content: left">
                <v-icon left>
                  mdi-email
                </v-icon>
                <span class="d-none d-sm-flex"
                  ><slot name="emailOptOutTabName">{{ $i18n.translate("Emails") }}</slot></span
                >
              </v-tab>
              <v-tab style="justify-content: left" v-if="!hidePayoutMethodsTab && bankAccountRequired">
                <v-icon left>
                  mdi-bank
                </v-icon>
                <span class="d-none d-sm-flex"><slot name="bankAccountTabName">Bank Accounts</slot></span>
              </v-tab>
              <v-tab style="justify-content: left" v-if="!hidePayoutMethodsTab">
                <v-icon left>
                  mdi-seal
                </v-icon>
                <v-badge icon="mdi-exclamation-thick" color="error" v-if="!validPayoutMethodForm">
                  <span class="d-none d-sm-flex"
                    ><slot name="payoutMethodTabName">{{ $i18n.translate("Payout Methods") }}</slot></span
                  >
                </v-badge>
                <span v-else>
                  <span class="d-none d-sm-flex"
                    ><slot name="payoutMethodTabName">{{ $i18n.translate("Payout Methods") }}</slot></span
                  >
                </span>
              </v-tab>
              <v-tab
                style="justify-content: left"
                v-if="$privilege.isGappInternalUser() || $privilege.isImpersonating()"
              >
                <v-icon left>
                  mdi-account-details
                </v-icon>
                <span class="d-none d-sm-flex">{{ $i18n.translate("Privileges") }}</span>
              </v-tab>
              <slot name="postTabs"></slot>

              <slot name="preTabItems"></slot>
              <v-tab-item :eager="true" v-if="!hideContactTab">
                <ProfileParticipantTab v-model="validParticipantForm" @onLoad="loaded++">
                  <template v-slot:messageWhenParticipantTabIsNotEditable>
                    <slot name="messageWhenParticipantTabIsNotEditable"></slot>
                  </template>
                </ProfileParticipantTab>
              </v-tab-item>
              <v-tab-item :eager="true" v-if="!hideOrganizationTab">
                <ProfileOrganizationTab v-model="validOrganizationForm" @onLoad="loaded++">
                  <template v-slot:messageWhenOrganizationIsNotEditable>
                    <slot name="messageWhenOrganizationIsNotEditable"></slot>
                  </template>
                </ProfileOrganizationTab>
              </v-tab-item>
              <v-tab-item :eager="true">
                <ProfileSecurityQuestionsTab v-model="validSecurityForm" @onLoad="loaded++" />
              </v-tab-item>
              <v-tab-item>
                <ProfilePasswordTab />
              </v-tab-item>
              <v-tab-item>
                <ProfileEmailTemplateUnsubscribeGroupTab />
              </v-tab-item>
              <v-tab-item v-if="!hidePayoutMethodsTab && bankAccountRequired">
                <BankAccountsTable :showTitle="false" :showProvider="false" :participant="selectedParticipant" />
              </v-tab-item>
              <v-tab-item v-if="!hidePayoutMethodsTab">
                <ApiError :errors="errors"></ApiError>
                <AwardVehicleSelectionPanel
                  buttonSelectText="Select as Preferred"
                  buttonSelectedText="Preferred"
                  :availableAwardVehicles="availableAwardVehicles"
                  v-model="selectedAwardVehicle"
                  @input="updatePreferredAwardVehicle"
                ></AwardVehicleSelectionPanel>
              </v-tab-item>
              <v-tab-item v-if="$privilege.isGappInternalUser() || $privilege.isImpersonating()">
                <ProfilePrivilegesTab />
              </v-tab-item>
              <slot name="postTabItems"></slot>
            </v-tabs>

            <v-dialog v-model="missingInfoDialog" max-width="500">
              <v-card>
                <v-card-title>
                  <span class="headline">Please Update Your Profile</span>
                </v-card-title>

                <v-card-text>
                  <p>We are missing some information for your account.</p>
                  <p>Please update your profile.</p>
                </v-card-text>

                <v-card-actions align="center">
                  <v-spacer></v-spacer>
                  <v-btn color="blueen-1" text @click="missingInfoDialog = false">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import ApiError from "../../display/ApiError.vue";
import BankAccountsTable from "../../tables/BankAccountsTable.vue";
import AwardVehicleSelectionPanel from "../awardVehicle/AwardVehicleSelectionPanel.vue";
import ProfileEmailTemplateUnsubscribeGroupTab from "./ProfileEmailTemplateUnsubscribeGroupTab";
import ProfileOrganizationTab from "./ProfileOrganizationTab";
import ProfileParticipantTab from "./ProfileParticipantTab";
import ProfilePasswordTab from "./ProfilePasswordTab";
import ProfilePrivilegesTab from "./ProfilePrivilegesTab.vue";
import ProfileSecurityQuestionsTab from "./ProfileSecurityQuestionsTab";

import { mapGetters } from "vuex";

export default {
  name: "ProfileCard",
  metaInfo: {
    title: "Profile"
  },
  components: {
    ProfileParticipantTab,
    ProfileOrganizationTab,
    ProfileSecurityQuestionsTab,
    ProfilePasswordTab,
    ProfileEmailTemplateUnsubscribeGroupTab,
    AwardVehicleSelectionPanel,
    BankAccountsTable,
    ProfilePrivilegesTab,
    ApiError
  },
  data() {
    return {
      loaded: 0,
      tab: 0,
      missing: false,
      firstTime: true,
      loading: false,
      errors: {},
      validParticipantForm: null,
      validOrganizationForm: null,
      validSecurityForm: null,
      missingInfoDialog: false,
      availableAwardVehicles: [],
      selectedAwardVehicle: undefined
    };
  },

  methods: {
    async initialize() {
      this.loading = true;
      this.missingInfoDialog = this.$route.query.missing && this.$route.query.missing.length > 0;
      if (this.hideContactTab && this.hideOrganizationTab) {
        this.tab = 2;
      }

      this.availableAwardVehicles = await this.$awardvehicle.getAvailableAwardVehicles(this.selectedParticipant);

      this.selectedAwardVehicle = await this.$awardvehicle.getPreferredAwardVehicle(this.selectedParticipant);

      this.loading = false;
    },
    updatePreferredAwardVehicle(awardVehicle) {
      let postParticipantForm = {
        preferredAwardVehicle: this.$api.getSelfUrl("awardVehicle", awardVehicle)
      };

      this.$api
        .patch("/api/participants/" + this.selectedParticipant.id, postParticipantForm)
        .then(() => {
          this.selectedParticipant.preferredAwardVehicle = awardVehicle;
          return this.$auth.storeSelectParticipant(this.selectedParticipant);
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        });
    }
  },
  updated() {
    if (this.firstTime && this.loaded == 3) {
      if (!this.validParticipantForm) {
        this.tab = 0;
      } else if (!this.validOrganizationForm) {
        this.tab = 1;
      } else if (!this.validSecurityForm) {
        this.tab = 2;
      }
      this.firstTime = false;
    }
  },
  created() {
    this.initialize();
  },

  computed: {
    ...mapGetters(["selectedParticipant"]),

    hideContactTab() {
      return (
        !this.selectedParticipant.taxable &&
        this.selectedParticipant.participantType.requireName === "INVISIBLE" &&
        this.selectedParticipant.participantType.requireAddress === "INVISIBLE" &&
        this.selectedParticipant.participantType.requirePhone === "INVISIBLE" &&
        this.selectedParticipant.participantType.requireEmail === "INVISIBLE" &&
        this.selectedParticipant.participantType.requireTermsAndConditions === "INVISIBLE"
      );
    },

    hideOrganizationTab() {
      if (this.selectedParticipant.organization) {
        return (
          this.selectedParticipant.organization.organizationType.requireName === "INVISIBLE" &&
          this.selectedParticipant.organization.organizationType.requireAddress === "INVISIBLE" &&
          this.selectedParticipant.organization.organizationType.requirePhone === "INVISIBLE" &&
          this.selectedParticipant.organization.organizationType.requireTin === "INVISIBLE"
        );
      }

      return true;
    },

    hidePayoutMethodsTab() {
      if (this.availableAwardVehicles && this.availableAwardVehicles.length > 0) {
        return false;
      }

      if (this.selectedParticipant.organization && this.selectedParticipant.organization.forcedAwardVehicle) {
        return false;
      }

      if (this.selectedParticipant.forcedAwardVehicle) {
        return false;
      }

      return true;
    },

    bankAccountRequired() {
      let ar = this.selectedParticipant.participantType.availableAwardVehicles.find(
        awv => awv.awardVehicleType.bankAccountRequired == true
      );
      return ar ? true : false;
    },

    validPayoutMethodForm() {
      return this.selectedParticipant.preferredAwardVehicle;
    }
  }
};
</script>
